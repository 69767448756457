.App {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body {
  background-color: #fff;
  padding: 0;
  box-sizing: border-box;
}
