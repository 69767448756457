.about-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container .about-content-style {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 30rem;
}
.about-container .about-content-style .about-content-header {
  display: flex;
  justify-content: space-between;
}
.about-container .about-content-style .close-button {
  background-color: #fff;
  color: #000;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 5rem;
}
.about-container .about-content-style article {
  margin-top: -3rem;
}
.about-container p {
  color: black;
  font-size: 1.3rem;
}/*# sourceMappingURL=about.css.map */