.about-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .about-content-style {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 30rem;
    .about-content-header {
      display: flex;
      justify-content: space-between;
    }
    .close-button {
      background-color: #fff;
      color: #000;
      border: none;
      font-size: 2rem;
      cursor: pointer;
      margin-bottom: 5rem;
    }
    article {
      margin-top: -3rem;
    }
  }
  p {
    color: black;
    font-size: 1.3rem;
  }
}
