.header-container {
  background-color: #4a4b4a;
  width: 100%;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title {
      h1 {
        color: #fff;
        font-size: 1.5rem;
        margin-left: 1rem;
      }
    }
    .about-button {
      border: none;
      background-color: #4a4b4a;
      font-size: 2rem;
      cursor: pointer;
      margin-right: 1rem;
      h1 {
        font-size: 1.5rem;
        color: #fff;
      }
      h1:hover {
        color: #e9f80e;
      }
    }
  }
}
