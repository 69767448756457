.weather-api-container {
  .label-input-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    label {
      font-size: 1.5rem;
      margin-bottom: 1%;
    }
    input {
      text-align: center;
      height: 2.5rem;
      width: 18rem;
      font-size: 1rem;
      margin-bottom: 1%;
      border-radius: 6px;
    }
    button {
      width: 18.5rem;
      margin-bottom: 1%;
      height: 3rem;
      border-radius: 6px;
      background-color: #4a4b4a;
      color: #e9f80e;
      font-size: 1rem;
      cursor: pointer;
    }
    button:hover {
      outline: #e9f80e 3px solid;
    }
  }
  .response-msg,
  .loading-msg,
  .outcome-msg,
  .error-msg {
    color: black;
    font-size: 1.3rem;
  }
  .google-loading-msg {
    margin-bottom: 1%;
    color: black;
    font-size: 1rem;
  }
  .google-address-msg {
    color: black;
    font-size: 1rem;
    margin-top: 1%;
    margin-bottom: 1.5%;
  }
}
