.footer-main-container .footer-container {
  background-color: #4a4b4a;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.footer-main-container .footer-container p {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1.3rem;
}
.footer-main-container .footer-container p a {
  text-decoration: none;
  color: #fff;
}
.footer-main-container .footer-container p a:hover {
  color: #e9f80e;
  cursor: pointer;
}/*# sourceMappingURL=footer.css.map */